import cx from "classnames";
import * as React from "react";
import { Link } from "gatsby";

import Post from "../components/Post";
import OuterContainer from "../components/OuterContainer";

export default () => {
  return (
    <>
      <Post
        html={""}
        id="thank-you-header"
        slug="/thanks/"
        publishedAt="I shall get back to you soonly."
        shouldPassThroughCloudinary={false}
        featureImage={
          "https://images.unsplash.com/photo-1549032305-e816fabf0dd2?auto=format&fit=crop&w=2850&q=80"
        }
        title={"Thanks you"}
        tags={[]}
      />
      <OuterContainer className={cx("bg-white", "mt-8", "text-center")}>
        <Link
          to="/"
          className={cx(
            "inline-block",
            "bg-black",
            "text-white",
            "font-bold",
            "text-current",
            "m-12",
            "px-24",
            "py-4",
            "transition-colors",
            "duration-300",
            "hover:text-black",
            "hover:bg-white"
          )}
        >
          Back to home
        </Link>
      </OuterContainer>
    </>
  );
};
